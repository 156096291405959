import React from 'react';
import './App.css';

function App() {
  return (
    <div>
      <section>
        <header>
        <p className="brand">maingram</p>
          {/* <img src=".images/logo.png" width="100" alt="maingram logo"></img> */}
          <div>
            <ul>
              <li><a href="#up">Up</a></li>
              <li><a href="#up">About</a></li>
              <li><a href="#up">Contact</a></li>
            </ul>
          </div>
        </header>
      </section>

      <section id="main">
        <h1 className="main-text">
          Learn <span>how to use</span> instagram<br></br> for your <span>business.</span>
        </h1>

        <div className="right-image">  <img src="./images/maingram_logo.png" alt="maingram logo "></img></div>
      </section>

      <div id="forma">
      <section>
      <form name="contact" method="post">
        <h2>Apply for the course</h2>
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>Your Name: <input className="input1" type="text" name="name"/></label>
          </p>
          <p>
            <label>Your Email: <input className="input1" type="email" name="email"/></label>
          </p>
          <p>
            <label>Comment: <textarea  rows="4" className="input1" name="message"></textarea></label>
          </p>
          <p>
            <button type="submit">Apply</button>
          </p>
        </form>
        </section>
      </div>
    </div>
  );
}

export default App;
